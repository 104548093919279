<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="Save">
        <md-card>
          <md-card-header>
            <h4 class="title">History</h4>
          </md-card-header>
          <ManagePatientHandler>
            <patient-history-form></patient-history-form>
          </ManagePatientHandler>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import ManagePatientHandler from "../../handlers/module-handlers/patient/ManagePatientHandler";
import { mapState } from "vuex";
import PatientHistoryForm from "./PatientHistoryForm.vue";
import NavigationBlocker from "../../components/NavigationBlocker";

export default {
  mixins: [NavigationBlocker],
  components: {
    ManagePatientHandler,
    PatientHistoryForm,
  },
  computed: {
    ...mapState({
      screeningDate: (state) => state.dashboard.screeningDates,
    }),
  },
  provide() {
    return {
      refreshList: () => {},
    };
  },
};
</script>
